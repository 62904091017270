export const socialLinks = [
	{
		id: 1,
		name: 'Website',
		icon: 'instagram',
		url: 'https://www.instagram.com/larissaschaerer/',
	},

	{
		id: 4,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'http://www.linkedin.com/in/larissa-schaerer-392577245 ',
	},
	{
		id: 5,
		name: 'YouTube',
		icon: 'youtube',
		url: 'https://youtube.com/@Larissa_Schaerer?si=FI7H4zfpIw9bx6yv',
	},
];
