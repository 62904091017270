// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: '',
		category: 'Photography',
		img: require('@/assets/images/Photography.jpg'),
	},
	
	{
		id: 2,
		title: '',
		category: 'Social media',
		img: require('@/assets/images/social media.jpg'),
	},
	
	{
		id: 3,
		title: '',
		category: 'Animation',
		img: require('@/assets/images/animation.png'),
	},
	
	{
	id: 4,
	title: '',
	category: 'Videos',
	img: require('@/assets/images/Videos.png'),
    },

];

export default projects;
